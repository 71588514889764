import React, { useEffect } from 'react';
import { get, set } from 'tiny-cookie';
import { client, fetcher } from './sdk/client';
import useSWR from 'swr';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { toast } from '@vapor/ui';
import { IUser } from './interfaces/user';
import { ISession } from './interfaces/session';

let COOKIE_NAME = 'weekrise_session';

let hasToken = typeof window == 'undefined' ? undefined : !!get(COOKIE_NAME);

export let getApiToken = () => get(COOKIE_NAME);

export let setApiToken = (value: string) =>
  set(COOKIE_NAME, value, {
    path: '/',
    domain: window.location.hostname.endsWith('weekrise.com') ? 'weekrise.com' : 'localhost',
    samesite: 'lax',
    'max-age': 60 * 60 * 24 * 365 * 2
  });

export let resetApiToken = () =>
  set(COOKIE_NAME, '', {
    path: '/',
    domain: window.location.hostname.endsWith('weekrise.com') ? 'weekrise.com' : 'localhost',
    'max-age': 0
  });

export let logoutRequest = () =>
  client
    .post('/user/logout', {
      headers: {
        'x-weekrise-session': getApiToken()
      }
    })
    .catch(err => {
      if (err?.response?.status == 401) notLoggedInHandler();
      Sentry.captureException(err);
      throw err;
    });

export let useUser = (canBeUnauthenticated = false) => {
  let { data, error, mutate, isValidating } = useSWR<{ user: IUser }>(
    ['/user', canBeUnauthenticated],
    fetcher
  );

  let updateUser = async (data: { name?: string; email?: string; photoUrl?: string }) => {
    let res = await client
      .put('/user', data, {
        headers: {
          'x-weekrise-session': getApiToken()
        }
      })
      .catch(err => {
        if (err?.response?.status == 401) notLoggedInHandler();
        Sentry.captureException(err);
        throw err;
      });

    mutate({ user: res.data.user }, false);
  };

  useEffect(() => {
    if (error) toast.error('Could not authenticate you');
  }, [error]);

  return { user: data?.user, error, loading: !data && !error && isValidating, updateUser };
};

export let useSessions = () => {
  let { data, error } = useSWR<{ sessions: ISession[] }>('/user/sessions', fetcher);

  return { sessions: data?.sessions, error, loading: !data && !error };
};

export let useIsLoggedIn = () => {
  let { user, loading } = useUser(true);

  return {
    loading: hasToken === false ? false : loading,
    loggedIn: hasToken === false ? false : typeof user?.id == 'string'
  };
};

export let notLoggedInHandler = () => {
  location.replace(
    '/auth/login?next=' +
      encodeURIComponent(
        location.protocol + '//' + location.host + location.pathname + location.search
      )
  );
};

export let MustBeLoggedIn = () => {
  let { loading, loggedIn } = useIsLoggedIn();

  useEffect(() => {
    if (loggedIn || loading) return;
    notLoggedInHandler();
  }, [loading, loggedIn]);

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `if (!document.cookie || document.cookie.indexOf('${COOKIE_NAME}=') === -1) {
              location.replace('/auth/login?next=' + encodeURIComponent(location.pathname + location.search))
            }`
        }}
      />
    </Head>
  );
};
