import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/nextjs';
import { config } from '../../config';
import { getApiToken, notLoggedInHandler } from '../user';

let client = axios.create({ baseURL: config.api });
axiosRetry(client, { retries: 3 });

export { client };

export let fetcher = async (url: string, canBeUnauthenticated = false) => {
  let token = getApiToken();

  if (!token && canBeUnauthenticated) return Promise.resolve(null);

  return client
    .get(url, {
      headers: {
        'x-weekrise-session': token
      }
    })
    .then(res => res.data)
    .catch(err => {
      if (err?.response?.status == 401) notLoggedInHandler();
      Sentry.captureException(err);
      throw err;
    });
};
