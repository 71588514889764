export let Logo = ({ size }: { size?: number | string }) => {
  return (
    <svg width={size || 30} viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.57129 17.619C8.57129 3.10976 12.6056 0 31.4284 0C50.2513 0 54.2856 3.10976 54.2856 17.619C54.2856 32.1283 50.2513 35.2381 31.4284 35.2381C12.6056 35.2381 8.57129 32.1283 8.57129 17.619Z"
        fill="#1E51C0"
      />
      <path
        d="M0 24C0 12.471 2.91225 10 16.5 10C30.0878 10 33 12.471 33 24C33 35.529 30.0878 38 16.5 38C2.91225 38 0 35.529 0 24Z"
        fill="#E983D4"
      />
      <path
        d="M15.2383 36.6666C15.2383 22.1573 19.1885 19.0475 37.6192 19.0475C56.05 19.0475 60.0002 22.1573 60.0002 36.6666C60.0002 51.1759 56.05 54.2856 37.6192 54.2856C19.1885 54.2856 15.2383 51.1759 15.2383 36.6666Z"
        fill="#FFC607"
      />
    </svg>
  );
};
