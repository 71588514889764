import { Avatar, Button, Popover, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { useIsLoggedIn, useUser } from '../../state/user';
import { UserPopover } from './userPopover';

let Wrapper = styled('div')`
  display: flex;
`;

let AvatarWrapper = styled('figure')`
  height: 30px;
  width: 30px;
`;

export let User = () => {
  let { loggedIn, loading } = useIsLoggedIn();
  let { user } = useUser(true);

  return (
    <Wrapper>
      {loggedIn || loading ? (
        <Popover
          mobile
          popover={({ state: { close } }) => <UserPopover user={user} close={close} />}
        >
          {({ ref, attrs }) => (
            <AvatarWrapper ref={ref} {...attrs}>
              <Avatar size={30} src={user?.photoUrl} name={user?.name || 'Loading...'} />
            </AvatarWrapper>
          )}
        </Popover>
      ) : (
        <>
          <div className="desktop">
            <Link href="/auth/login">
              <a>
                <Button variant="secondary" size="small">
                  Login
                </Button>
              </a>
            </Link>

            <Spacer width={10} />

            <Link href="/auth/signup">
              <a>
                <Button variant="primary" size="small">
                  Signup
                </Button>
              </a>
            </Link>
          </div>
        </>
      )}
    </Wrapper>
  );
};
