import { Button, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { keyframes, styled } from 'buttered';
import React, { useState } from 'react';
import Link from 'next/link';
import { Logo } from '../../components/logo';
import { useIsLoggedIn } from '../../state/user';
import { User } from './user';
import { Menu, X } from 'react-feather';
import useDelayed from 'use-delayed';
import clsx from 'clsx';

let Wrapper = styled('nav')`
  padding: 15px 20px;
  z-index: 5;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: all 0.3s;
  border-bottom: solid transparent 1px;
  color: white;

  &.scrolled {
    border-bottom: var(--vapor-border);
    background: rgba(255, 255, 255, 0.9);
    color: black;
  }
`;

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

let fadeOut = keyframes`
 from {


    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-15px);
  }
`;

let Inner = styled('div')`
  display: flex;
  max-width: 1050px;
  margin: 0px auto;

  .desktop {
    display: flex;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  .mobile {
    display: flex;

    @media screen and (min-width: 720px) {
      display: none;
    }
  }
`;

let Left = styled('div')`
  display: flex;
  flex-grow: 1;
`;

let Right = styled('div')`
  display: flex;
`;

let LogoWrapper = styled('figure')`
  display: flex;
`;

let MobileNav = styled('div')`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1020;
  padding: 30px;
  display: flex;
  transition: all 0.3s;
`;

let MobileNavInner = styled('nav')`
  border: var(--vapor-border);
  border-radius: var(--vapor-radius-big);
  padding: 15px;
  background: var(--vapor-background);
  flex-grow: 1;
  height: fit-content;

  &.show {
    animation: ${fadeIn} 0.5s forwards;
  }

  &.hide {
    animation: ${fadeOut} 0.5s forwards;
  }
`;

let MobileNavHeader = styled('header')`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: var(--vapor-border);
  display: flex;
`;

export let Nav = ({ scrolled }: { scrolled?: boolean }) => {
  let [mobileNavOpen, setMobileNavOpen] = useState(false);
  let delayedMobileNavOpen = useDelayed(mobileNavOpen, 300, [true]);
  let { loggedIn } = useIsLoggedIn();

  return (
    <Wrapper className={clsx({ scrolled })}>
      <Inner>
        <Left>
          <div className="mobile">
            <Button
              size="small"
              aria-label="Open Navigation"
              icon={<Menu />}
              variant="tertiary"
              onClick={() => setMobileNavOpen(true)}
            />

            <Spacer width={10} />
          </div>

          <Link href="/">
            <a style={{ display: 'flex', color: 'unset' }}>
              <LogoWrapper>
                <Logo />

                <Spacer width={10} />

                <div>
                  <VerticalCenter>
                    <Text size={18} as="h1" weight={500} color="inherit">
                      Weekrise
                    </Text>
                  </VerticalCenter>
                </div>
              </LogoWrapper>
            </a>
          </Link>
        </Left>

        <Right>
          <div className="desktop">
            {loggedIn && (
              <Link href="https://app.weekrise.com">
                <a>
                  <Button size="small" variant="primary">
                    Open Calendar
                  </Button>
                </a>
              </Link>
            )}
          </div>

          <Spacer width={10} />

          <User />
        </Right>
      </Inner>

      {delayedMobileNavOpen && (
        <MobileNav
          onClick={() => setMobileNavOpen(false)}
          style={{
            opacity: mobileNavOpen ? 1 : 0
          }}
        >
          <MobileNavInner
            onClick={e => e.stopPropagation()}
            className={clsx({
              show: mobileNavOpen,
              hide: !mobileNavOpen
            })}
          >
            <MobileNavHeader>
              <VerticalCenter>
                <LogoWrapper>
                  <Logo />

                  <Spacer width={10} />

                  <div>
                    <VerticalCenter>
                      <Text size={16} as="h1" weight={500}>
                        Weekrise
                      </Text>
                    </VerticalCenter>
                  </div>
                </LogoWrapper>
              </VerticalCenter>

              <Spacer />

              <Button
                size="small"
                aria-label="Close Navigation"
                icon={<X />}
                variant="tertiary"
                onClick={() => setMobileNavOpen(false)}
              />
            </MobileNavHeader>

            {loggedIn ? (
              <>
                <Link href="https://app.weekrise.com">
                  <a>
                    <Button variant="primary">Open Calendar</Button>
                  </a>
                </Link>
              </>
            ) : (
              <>
                <Link href="/auth/login">
                  <a>
                    <Button>Login</Button>
                  </a>
                </Link>

                <Spacer width={10} />

                <Link href="/auth/signup">
                  <a>
                    <Button variant="primary">Signup</Button>
                  </a>
                </Link>
              </>
            )}
          </MobileNavInner>
        </MobileNav>
      )}
    </Wrapper>
  );
};
