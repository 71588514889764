import { Button, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { Logo } from './logo';

let Wrapper = styled('footer')`
  border-top: var(--vapor-border);
  background: var(--vapor-accent-0);
  padding: 60px 15px;
  text-align: center;

  @media screen and (max-width: 720px) {
    padding: 20px 15px;
  }
`;

let Actions = styled('div')`
  display: flex;
  width: fit-content;
  margin: 30px auto 0px auto;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: calc(100% - 30px);
  }
`;

export let Footer = () => {
  return (
    <Wrapper>
      <Logo size={50} />

      <Spacer height={25} />

      <Text as="p" size={22} weight={600} color="inherit">
        Weekrise
      </Text>

      <Spacer height={10} />

      <Text as="p" size={16} weight={500} color="inherit">
        Made with{' '}
        <span aria-label="love" style={{ color: 'var(--vapor-red)' }}>
          ❤
        </span>{' '}
        by{' '}
        <a href="https://twitter.com/tobiasherber_" target="_blank" rel="noopener noreferrer">
          Tobias Herber
        </a>
      </Text>

      <Actions>
        <Link href="/">
          <a>
            <Button size="small">Weekrise</Button>
          </a>
        </Link>

        <Link href="/privacy">
          <a>
            <Button size="small">Privacy</Button>
          </a>
        </Link>

        <Link href="/terms">
          <a>
            <Button size="small">Terms Of Service</Button>
          </a>
        </Link>

        <Link href="/payments">
          <a>
            <Button size="small">Payment Policy</Button>
          </a>
        </Link>

        <Link href="/imprint">
          <a>
            <Button size="small">Imprint</Button>
          </a>
        </Link>

        <a href="https://twitter.com/tobiasherber_">
          <Button size="small">Twitter</Button>
        </a>
      </Actions>
    </Wrapper>
  );
};
