let config = {
  api: 'https://api.weekrise.com'
};

if (process.env.NODE_ENV != 'production') {
  config = {
    api: 'http://localhost:34342'
  };
}

export { config };
